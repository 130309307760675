
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import moment from 'moment';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import { PermissionsGroup } from '@/store/models.def';
import gdbx from '@/store/modules/gdbx';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class MonthPurchaseNumberDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public salesCount = 0;
	public purchaseAmount = '' || 0;

	public get permissionIds(): PermissionsGroup[] {
		return ['purchases'];
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currentMonthRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).startOf('month').valueOf(),
			moment(this.selectedAsOfDate).endOf('month').valueOf(),
		];
	}

	public get currency() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			selectedSuppliers,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedAgents,
			selectedStockItems,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('purchases')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange);

		const CNRef = FilteredDatabase.ref('purchaseReturn')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange);

		const purchaseAmount = await (await tradeRef.get()).getSum('amount');
		const CNAmount = await (await CNRef.get()).getSum('amount');

		this.purchaseAmount = purchaseAmount - CNAmount;

		this.saveHistory('purchaseAmount');
	}
}
