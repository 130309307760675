
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import StickyFilterContainer from '@/components/reports-v2/components/pages/StickyFilterContainer.vue';
import YTDPurchaseNumberDisplayer from '@/components/reports-v2/components/codedWidgets/purchase/YTDPurchaseNumberDisplayer.vue';
import MonthPurchaseNumberDisplayer from '@/components/reports-v2/components/codedWidgets/purchase/MonthPurchaseNumberDisplayer.vue';
import MonthPurchaseContributionNumberDisplayer from '@/components/reports-v2/components/codedWidgets/purchase/MonthPurchaseContributionNumberDisplayer.vue';
import PurchaseAnalysisTreeMap from '@/components/reports-v2/components/codedWidgets/purchase/PurchaseAnalysisTreeMap.vue';
import userx from '@/store/modules/userx';
import router from '@/router';
import moment from 'moment';
import DateFilter from '@/components/reports-v2/components/filters/DateFilter.vue';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import gdbx from '@/store/modules/gdbx';
import LastSync from '@/components/LastSync.vue';
import StockItemPurchaseBySupplierTable from '@/components/reports-v2/components/codedWidgets/stock/StockItemPurchaseBySupplierTable.vue';
import BasePageContainer from '../BasePageContainer.vue';
import SupplierPurchaseTrendBarLineChart from '@/components/reports-v2/components/codedWidgets/sales/SupplierPurchaseTrendBarLineChart.vue';
import { ValueText } from '@/store/models.def';

@Component({
	components: {
		YTDPurchaseNumberDisplayer,
		MonthPurchaseNumberDisplayer,
		MonthPurchaseContributionNumberDisplayer,
		StickyFilterContainer,
		DateFilter,
		PurchaseAnalysisTreeMap,
		LastSync,
		StockItemPurchaseBySupplierTable,
		SupplierPurchaseTrendBarLineChart,
		SelectFilter,
	},
})
export default class PurchaseOverview extends BasePageContainer {
	public selectedDay: number = moment().endOf('day').valueOf();
	public defaultSupplier: string | null = null;

	@Watch('selectedSuppliers', { immediate: true })
	public onSelectedSuppliersChange(newVal: Array<ValueText<string>>) {
		if (newVal[0].value) {
			this.defaultSupplier = newVal[0].value;
		}
	}

	public mounted() {
		this.track();
	}

	public track() {
		this.$ga.set('userId', userx.user!.uid + '_' + userx.user!.email);
		this.$ga.page(router);
	}

	public get selectedAsOfDate() {
		return moment(this.selectedDay).endOf('month').valueOf();
	}

	public get selectedCurrentMonthRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('month').valueOf(),
			moment(this.selectedDay).endOf('month').valueOf(),
		];
	}

	public get currentYearRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('year').valueOf(),
		];
	}

	public get selectedYearToDateRange(): [number, number] {
		return [
			moment(this.selectedDay).startOf('year').valueOf(),
			moment(this.selectedDay).endOf('month').valueOf(),
		];
	}

	public get selectedAgents() {
		return gdbx.allAgents.map((vt) => vt.value);
	}

	public get selectedStockItems() {
		return gdbx.allStockItems.map((vt) => vt.value);
	}

	public get selectedSuppliers() {
		return gdbx.allSuppliers.map((vt) => vt.value);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
