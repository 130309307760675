
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import AmountDisplayerFilterable from '@/components/reports-v2/components/filterables/AmountDisplayerFilterable.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '../FilterWidget.vue';
import {
	CustomerSupplierTradeCard,
	PermissionsGroup,
	PurchaseDetailCard,
} from '@/store/models.def';
import moment from 'moment';

@Component({
	components: {
		AmountDisplayerFilterable,
	},
})
export default class YTDPurchaseNumberDisplayer extends FilterWidget {
	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public purchaseAmount = 0;
	public supplierCount = 0;
	public activeCustomersCount = 0;
	public servingYear = '' || 0;

	@Watch('servingYear', { immediate: true })
	public onServingYearChange(newVal) {
		this.$emit('returnServingYear', this.servingYear);
	}

	public get oneYearBeforeRange(): [number, number] {
		return [
			moment(this.selectedAsOfDate).add(-1, 'year').startOf('month').valueOf(),
			moment(this.selectedAsOfDate).endOf('month').valueOf(),
		];
	}

	public get permissionIds(): PermissionsGroup[] {
		return ['purchases', 'suppliers'];
	}

	public get expensiveHook() {
		const {
			selectedDateRange,
			selectedAsOfDate,
			selectedStockItems,
			selectedAgents,
			selectedSuppliers,
		} = this;
		return JSON.stringify([
			selectedDateRange,
			selectedAsOfDate,
			selectedStockItems,
			selectedAgents,
			selectedSuppliers,
		]);
	}

	public async expensiveCalc() {
		const tradeRef = FilteredDatabase.ref('purchases')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange);

		const tradeCNRef = FilteredDatabase.ref('purchaseReturn')
			.suppliers(this.selectedSuppliers)
			.agents(this.selectedAgents)
			.dateRange(this.selectedDateRange);

		const tradeRefHead = await tradeRef.get();
		const tradeCNRefHead = await tradeCNRef.get();

		this.purchaseAmount =
			(await tradeRefHead.getSum('amount')) -
			(await tradeCNRefHead.getSum('amount'));

		// Active suppliers

		const globalRef = FilteredDatabase.ref('globalSuppliers')
			.agents(this.selectedAgents)
			.numberRange('firstDeal', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		let globalCards: CustomerSupplierTradeCard[] = await (
			await globalRef.get()
		).getCards();

		globalCards = globalCards.filter((item) => item.firstDeal !== null);

		this.supplierCount = globalCards.length;

		const ivdnRef = FilteredDatabase.ref('supplierPiSds')
			.agents(this.selectedAgents)
			.dateRange(this.oneYearBeforeRange);

		this.activeCustomersCount = [
			...(await (await ivdnRef.get()).getSet('code')),
		].length;

		const salesRef = FilteredDatabase.ref('purchases')
			.agents(this.selectedAgents)
			.dateAsOf(this.selectedAsOfDate);

		const salesCards: PurchaseDetailCard[] = await (
			await salesRef.get()
		).getCards();

		const servingYearsList = salesCards.map((item) => moment(item.date).year());

		this.servingYear = [...new Set(servingYearsList)].length;

		this.saveHistory('purchaseAmount');
	}
}
