
import { Vue, Component, Prop, Watch } from 'vue-property-decorator';
import * as echarts from 'echarts';
import SecondaryLineBarChartFilterable from '@/components/reports-v2/components/filterables/SecondaryLineBarChartFilterable.vue';
import moment from 'moment';
import SelectFilter from '@/components/reports-v2/components/filters/SelectFilter.vue';
import { FilteredDatabase } from '@/worker/fd/FilteredDatabase';
import FilterWidget from '@/components/reports-v2/components/codedWidgets/FilterWidget.vue';
import { PermissionsGroup, ValueText } from '@/store/models.def';
import { GobiColor } from '@/helpers/color';
import gdbx from '@/store/modules/gdbx';
import { DataViewFormatter } from '@/components/reports-v2/components/elements/charts/helpers/dataViewFormatter';
import Button from 'primevue/button';

@Component({
	components: {
		SecondaryLineBarChartFilterable,
		SelectFilter,
		Button,
	},
})
export default class SupplierPurchaseTrendBarLineChart extends FilterWidget {
	public get permissionIds(): PermissionsGroup[] {
		return ['purchases', 'suppliers'];
	}

	public filterIds: Array<
		| 'date'
		| 'dateAsOf'
		| 'dateRange'
		| 'stockItems'
		| 'agents'
		| 'customers'
		| 'suppliers'
	> = [];

	public xAxisData: string[] = [];
	public periodTotalData: any[] = [];
	public series: echarts.ECharts[] | echarts.ECharts | any = [];

	public selectedColumn: string = this.columnList[0].value;
	public selectedLine: string = this.lineList[0].value;
	public selectedComparedYear: number | null = this.currentYear - 1;
	public isConfirmApply: number = 0;

	public columnDatas: number[] = [];
	public comparedColumnDatas: number[] = [];
	public accumulatedLineResults: number[] = [];
	public accumulatedComparedLineResults: number[] = [];

	public applyFilter() {
		this.isConfirmApply += 1;
	}

	public get yAxisLabel2() {
		let label: string = '';

		// if (this.selectedLine === 'salesMargin') {
		// 	label = '%';
		// }

		if (
			this.selectedLine === 'cummulativePurchases' ||
			this.selectedLine === 'cummulativeCreditNote' ||
			this.selectedLine === 'outstanding'
		) {
			label = this.currencySymbol;
		}
		return label;
	}

	public get compareYearList() {
		const currentYear = moment().year();

		const list: number[] = [];

		for (let i = 1; i < 30; i++) {
			const year = currentYear - i;
			list.push(year);
		}

		return list;
	}

	public get timeframe() {
		const timeframeList = [
			'Monthly',
			'3 months',
			'6 months',
		];
		return timeframeList;
	}

	public get selectedLineName() {
		return this.lineList.find((item) => item.value === this.selectedLine)!.text;
	}

	public get columnList(): any[] {
		const list = [
			{ text: 'Purchase', value: 'purchase' },
			{ text: 'Credit Note', value: 'creditNote' },
			{ text: 'Payment', value: 'payment' },
		];

		return list;
	}

	public get lineList(): ValueText[] {
		const list = [
			{ text: 'None', value: 'none' },
			{ text: 'Outstanding', value: 'outstanding' },
			// { text: 'Sales Margin', value: 'salesMargin' },
			{ text: 'Cummulative Purchases', value: 'cummulativePurchases' },
			{ text: 'Cummulative Credit Note', value: 'cummulativeCreditNote' },
		];

		return list;
	}

	public get currentYear() {
		return moment(this.selectedAsOfDate).year();
	}

	public get currentMonth() {
		return moment(this.selectedAsOfDate).month();
	}

	public get compareYearRange(): [number, number] {
		let diff = 0;
		if (this.selectedComparedYear) {
			diff = moment(this.selectedAsOfDate).year() - this.selectedComparedYear;
		}
		return [
			moment(this.selectedAsOfDate)
				.add(-diff, 'year')
				.startOf('year')
				.valueOf(),
			moment(this.selectedAsOfDate).add(-diff, 'year').endOf('year').valueOf(),
		];
	}

	public get currencySymbol() {
		return gdbx.currencySymbol;
	}

	public get decimal() {
		return gdbx.numDecimal;
	}

	public get dataViewFormatter() {
		return DataViewFormatter.salesTracker;
	}

	public get expensiveHook() {
		const { isConfirmApply, currentYear, selectedSuppliers, currentMonth } =
			this;
		return JSON.stringify([
			isConfirmApply,
			currentYear,
			selectedSuppliers,
			currentMonth,
		]);
	}

	public async expensiveCalc() {
		const currentMonthIndex = this.currentMonth + 1;

		// As Of Selected Date

		const asOfPisdRef = FilteredDatabase.ref('supplierPiSds')
			.dateAsOf(this.selectedAsOfDate)
			.suppliers(this.selectedSuppliers);

		const asOfKoRef = FilteredDatabase.ref('supplierKos')
			.suppliers(this.selectedSuppliers)
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.selectedAsOfDate],
			]);

		const asOfUnappliedAmountRef = FilteredDatabase.ref('supplierTrades')
			.suppliers(this.selectedSuppliers)
			.dateAsOf(this.selectedAsOfDate);

		// As Of Compared Date

		const asOfComparedYearPisdRef = FilteredDatabase.ref('supplierPiSds')
			.suppliers(this.selectedSuppliers)
			.numberRange('date', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.compareYearRange[0]],
			]);

		const asOfComparedYearKoRef = FilteredDatabase.ref('supplierKos')
			.suppliers(this.selectedSuppliers)
			.numberRange('koDate', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.compareYearRange[0]],
			]);

		const asOfComparedYearUnappliedAmountRef = FilteredDatabase.ref(
			'supplierKos',
		)
			.suppliers(this.selectedSuppliers)
			.numberRange('date', [
				['>', Number.NEGATIVE_INFINITY],
				['<=', this.compareYearRange[0]],
			]);

		// Selected date

		const purchaseRef = FilteredDatabase.ref('purchases')
			.dateRange(this.selectedDateRange)
			.suppliers(this.selectedSuppliers);

		const purchaseCNRef = FilteredDatabase.ref('purchaseReturn')
			.dateRange(this.selectedDateRange)
			.suppliers(this.selectedSuppliers);

		const pisdRef = FilteredDatabase.ref('supplierPiSds')
			.dateRange(this.selectedDateRange)
			.suppliers(this.selectedSuppliers);

		const tradeRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(this.selectedSuppliers);

		// const tradePisdRef = FilteredDatabase.ref('supplierTrades')
		// 	.dateRange(this.selectedDateRange)
		// 	.suppliers(this.selectedSuppliers)
		// 	.includes('docType', ['PI', 'SD']);

		const tradeCNRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(this.selectedSuppliers)
			.includes('docType', ['CN']);

		const paymentRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.selectedDateRange)
			.suppliers(this.selectedSuppliers)
			.includes('docType', ['PM']);

		const koRef = FilteredDatabase.ref('supplierKos')
			.suppliers(this.selectedSuppliers)
			.dateRange(this.selectedDateRange)
			.numberRange('koDate', [
				['>=', this.selectedDateRange[0]],
				['<=', this.selectedDateRange[1]],
			]);

		// Compared Year

		const purchaseComparedYearRef = FilteredDatabase.ref('purchases')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers);

		const purchaseCNComparedYearRef = FilteredDatabase.ref('purchaseReturn')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers);

		const pisdComparedYearRef = FilteredDatabase.ref('supplierPiSds')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers);

		const tradeComparedYearRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers);

		const tradePisdComparedYearRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers)
			.includes('docType', ['PI', 'SD']);

		const tradeCNComparedYearRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers)
			.includes('docType', ['CN']);

		const paymentComparedYearRef = FilteredDatabase.ref('supplierTrades')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers)
			.includes('docType', ['PM']);

		const koComparedYearRef = FilteredDatabase.ref('supplierKos')
			.dateRange(this.compareYearRange)
			.suppliers(this.selectedSuppliers)
			.numberRange('koDate', [
				['>=', this.compareYearRange[0]],
				['<=', this.compareYearRange[1]],
			]);

		let pa = 0.07;
		let pb = 0;

		const purchaseDD = await this._loadDimensionByPeriod(
			purchaseRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const purchaseCNDD = await this._loadDimensionByPeriod(
			purchaseCNRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const paymentDD = await this._loadDimensionByPeriod(
			paymentRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const pisdDD = await this._loadDimensionByPeriod(
			pisdRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const koDD = await this._loadDimensionByPeriod(
			koRef,
			'month',
			pa,
			pb,
			'amount',
			'koDate',
		);

		pb += pa;
		pa = 0.07;

		const gainLossDD = await this._loadDimensionByPeriod(
			koRef,
			'month',
			pa,
			pb,
			'gainLoss',
		);

		pb += pa;
		pa = 0.07;

		const unappliedAmountDD = await this._loadDimensionByPeriod(
			tradeRef,
			'month',
			pa,
			pb,
			'unappliedAmount',
		);

		// Compared Year DD

		pb += pa;
		pa = 0.07;

		const purchaseComparedYearDD = await this._loadDimensionByPeriod(
			purchaseComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const purchaseCNComparedYearDD = await this._loadDimensionByPeriod(
			purchaseCNComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const pisdComparedYearDD = await this._loadDimensionByPeriod(
			pisdComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const paymentComparedYearDD = await this._loadDimensionByPeriod(
			paymentComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
		);

		pb += pa;
		pa = 0.07;

		const koComparedYearDD = await this._loadDimensionByPeriod(
			koComparedYearRef,
			'month',
			pa,
			pb,
			'amount',
			'koDate',
		);

		pb += pa;
		pa = 0.07;

		const gainLossComparedYearDD = await this._loadDimensionByPeriod(
			koComparedYearRef,
			'month',
			pa,
			pb,
			'gainLoss',
		);

		pb += pa;
		pa = 0.07;

		const unappliedAmountComparedYearDD = await this._loadDimensionByPeriod(
			tradeComparedYearRef,
			'month',
			pa,
			pb,
			'unappliedAmount',
		);

		this.generateLoadingText(1);

		let lineDatas: number[] = [];
		let comparedLineDatas: number[] = [];

		switch (this.selectedColumn) {
			case 'purchase':
				this.columnDatas = purchaseDD.map(
					(dd, index) => dd.sum - purchaseCNDD[index].sum,
				);
				this.comparedColumnDatas = purchaseComparedYearDD.map(
					(dd, index) => dd.sum - purchaseCNComparedYearDD[index].sum,
				);
				break;
			case 'creditNote':
				this.columnDatas = purchaseCNDD.map((dd) => dd.sum);
				this.comparedColumnDatas = purchaseCNComparedYearDD.map((dd) => dd.sum);
				break;
			case 'payment':
				this.columnDatas = paymentDD.map((dd) => dd.sum);
				this.comparedColumnDatas = paymentComparedYearDD.map((dd) => dd.sum);
				break;
		}

		switch (this.selectedLine) {
			case 'outstanding':
				const accumulatedIvDnAmount =
					(await (await asOfPisdRef.get()).getSum('amount')) -
					(await (await asOfKoRef.get()).getSum('amount')) -
					(await (await asOfKoRef.get()).getSum('gainLoss')) -
					(await (
						await asOfUnappliedAmountRef.get()
					).getSum('unappliedAmount'));
				const accumulatedComparedIvDnAmount =
					(await (await asOfComparedYearPisdRef.get()).getSum('amount')) -
					(await (await asOfComparedYearKoRef.get()).getSum('amount')) -
					(await (await asOfComparedYearKoRef.get()).getSum('gainLoss')) -
					(await (
						await asOfComparedYearUnappliedAmountRef.get()
					).getSum('unappliedAmount'));

				lineDatas = pisdDD.map((dd, index) =>
					index === 0
						? accumulatedIvDnAmount
						: 0 +
						  dd.sum -
						  koDD[index].sum -
						  gainLossDD[index].sum -
						  unappliedAmountDD[index].sum,
				);
				comparedLineDatas = pisdComparedYearDD.map((dd, index) =>
					index === 0
						? accumulatedComparedIvDnAmount
						: 0 +
						  dd.sum -
						  koComparedYearDD[index].sum -
						  gainLossComparedYearDD[index].sum -
						  unappliedAmountComparedYearDD[index].sum,
				);
				break;
			case 'cummulativePurchases':
				lineDatas = purchaseDD.map(
					(dd, index) => dd.sum - purchaseCNDD[index].sum,
				);
				comparedLineDatas = purchaseComparedYearDD.map(
					(dd, index) => dd.sum - purchaseCNComparedYearDD[index].sum,
				);
				break;
			case 'none':
				break;
		}

		for (let i = 0; i < 12; i++) {
			if (i === 0) {
				this.accumulatedComparedLineResults[i] = comparedLineDatas[i];
			} else {
				this.accumulatedComparedLineResults[i] =
					comparedLineDatas[i] + this.accumulatedComparedLineResults[i - 1];
			}
			// if (this.selectedLine === 'salesMargin') {
			// 	this.accumulatedComparedLineResults[i] =
			// 		(accumulatedComparedProfitDatas[i] /
			// 			accumulatedComparedSalesDatas[i]) *
			// 		100;
			// }
		}

		for (let i = 0; i < currentMonthIndex; i++) {
			if (i === 0) {
				this.accumulatedLineResults[i] = lineDatas[i];
			} else {
				this.accumulatedLineResults[i] =
					lineDatas[i] + this.accumulatedLineResults[i - 1];
			}
			// if (this.selectedLine === 'salesMargin') {
			// 	this.accumulatedLineResults[i] =
			// 		(accumulatedProfitDatas[i] / accumulatedSalesDatas[i]) * 100;
			// }
		}

		const yearDatas: echarts.ECharts | any = {
			name:
				this.selectedColumn[0].toUpperCase() +
				this.selectedColumn.substring(1) +
				' ' +
				this.currentYear,
			type: 'bar',
			barWidth: '30%',
			itemStyle: { color: GobiColor.COLOR1 },
			data: this.columnDatas,
		};

		const comparedYearDatas: echarts.ECharts | any = {
			name:
				this.selectedColumn[0].toUpperCase() +
				this.selectedColumn.substring(1) +
				' ' +
				this.selectedComparedYear,
			type: 'bar',
			itemStyle: { color: GobiColor.COLOR2 },
			barWidth: '30%',
			data: this.comparedColumnDatas,
		};

		// const invisibleDatas: echarts.ECharts | any = {
		// 	name: '',
		// 	type: 'bar',
		// 	itemStyle: { opacity: 0 },
		// 	barWidth: '1%',
		// 	data: { value: this.invisibleMax, label: { show: false } },
		// };

		const line: echarts.ECharts | any = {
			name: this.selectedLineName + ' ' + this.currentYear,
			type: 'line',
			itemStyle: { color: GobiColor.COLOR6 },
			yAxisIndex: 1,
			data: this.accumulatedLineResults,
		};

		const comparedLine: echarts.ECharts | any = {
			name: this.selectedLineName + ' ' + this.selectedComparedYear,
			type: 'line',
			itemStyle: { color: GobiColor.COLOR7 },
			yAxisIndex: 1,
			data: this.accumulatedComparedLineResults,
		};

		this.xAxisData = pisdComparedYearDD
			.map((dd) => dd.text)
			.map((month) => month.split(' '))
			.map((splitMonth) => splitMonth[0]);

		this.series = [];
		if (this.selectedComparedYear !== null) {
			this.series = [yearDatas, comparedYearDatas, line, comparedLine];
		} else {
			this.series = [yearDatas, line];
		}

		this.saveHistory(
			'series',
			'xAxisData',
			'columnDatas',
			'comparedColumnDatas',
			'accumulatedLineResults',
			'accumulatedComparedLineResults',
			'selectedComparedYear',
		);
	}

	public onHovering(value: boolean, filterId: string) {
		this.$emit('hovering', value, filterId);
	}
	public onEditing(value: boolean, filterId: string) {
		this.$emit('editing', value, filterId);
	}
}
